import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { initWeb3Onboard } from './services'
import {
  useConnectWallet,
  useNotifications,
  useSetChain,
  useWallets
} from '@web3-onboard/react'
import './App.css'
import Header from './views/Header/Header.js'
import Footer from './views/Footer/Footer.js'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

// if (window.innerWidth < 700) {
//   new VConsole()
// }

let provider

const App = () => {
  const [{ wallet }, connect] = useConnectWallet()
  const [{ connectedChain }, setChain] = useSetChain()
  const connectedWallets = useWallets()
  const [web3Onboard, setWeb3Onboard] = useState(null)

  const [message, setMessage] = useState('')
  const [signature, setSignature] = useState('')
  const [uid, setUid] = useState('')
  const [mac_random_str, setMacRandomStr] = useState('')
  const [random_str, setRandomStr] = useState('')
  const [t, setT] = useState('')
  const [platform, setPlatform] = useState('')
  const [verifyed, setVerifyed] = useState(false)
  // default test transaction to Goerli

  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard)
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const message = urlParams.get('message')
    const uid = urlParams.get('uid')
    const mac_random_str = urlParams.get('mac_random_str')
    const platform = urlParams.get('platform')
    const t = urlParams.get('t')
    const random_str = urlParams.get('random_str')
    setMessage(message || '')
    setUid(uid || '')
    setMacRandomStr(mac_random_str || '')
    setT(t || '')
    setPlatform(platform || '')
    setRandomStr(random_str || '')
  }, [])

  useEffect(() => {
    if (!connectedWallets.length) return

    const connectedWalletsLabelArray = connectedWallets.map(
      ({ label }) => label
    )
    // Check for Magic Wallet user session
    if (connectedWalletsLabelArray.includes('Magic Wallet')) {
      const [magicWalletProvider] = connectedWallets.filter(
        provider => provider.label === 'Magic Wallet'
      )
      async function setMagicUser() {
        try {
          const { email } =
            await magicWalletProvider.instance.user.getMetadata()
          const magicUserEmail = localStorage.getItem('magicUserEmail')
          if (!magicUserEmail || magicUserEmail !== email)
            localStorage.setItem('magicUserEmail', email)
        } catch (err) {
          throw err
        }
      }
      setMagicUser()
    }
  }, [connectedWallets, wallet])

  useEffect(() => {
    if (!wallet?.provider) {
      provider = null
    } else {
      provider = new ethers.providers.Web3Provider(wallet.provider, 'any')
    }
  }, [wallet])

  // useEffect(() => {
  //   ethMainnetGasBlockPrices.subscribe(estimates => {
  //     setBNGasPrices(estimates[0].blockPrices[0].estimatedPrices)
  //   })
  // }, [])

  const readyToTransact = async () => {
    if (!wallet) {
      const walletSelected = await connect()
      if (!walletSelected) return false
    }
    // prompt user to switch to Goerli for test
    // await setChain({ chainId: toChain })

    return true
  }

  useEffect(() => {
    const fetchVerify = async () => {
      console.log('signature:', signature)
      if (!message) {
        return
      }

      try {
        const response = await axios.get(
          'https://api.socialbot.club/api/verify',
          // 'http://127.0.0.1:8000/api/verify',
          {
            params: {
              message: message,
              address: wallet?.accounts[0]?.address,
              txhash: signature,
              uid: uid,
              platform: platform,
              mac_random_str: mac_random_str,
              random_str: random_str,
              t: t
            }
          }
        )
        if (response.data.status === 0) {
          toast.error(response.data.error)
        } else {
          toast.success('Verification successful')
        }
        setVerifyed(true)
      } catch (error) {
        console.error('Error:', error)
        toast.error(error)
      }
    }
    fetchVerify()
  }, [signature])

  const signMessage = async () => {
    if (!message) {
      toast.error('sign message required.')
      return
    }

    const signer = provider.getUncheckedSigner()
    try {
      const rc = await signer.signMessage(message)
      console.log('rc:', rc)
      setSignature(rc)
    } catch (error) {
      console.error('Error:', error)
      toast.error(error.message)
    }
  }
  const handleSignMessage = async () => {
    const ready = await readyToTransact()
    if (!ready) return
    await signMessage()
  }

  if (!web3Onboard) return <div>Loading...</div>

  return (
    <main>
      <Header
        connectedChain={wallet ? connectedChain : null}
        address={wallet?.accounts[0]?.address}
        balance={wallet?.accounts[0]?.balance}
        ens={wallet?.accounts[0]?.ens}
      />
      <section className="main">
        <div className="main-content">
          <div className="vertical-main-container">
            <div className="container notify">
              <h2>Link you web3 address</h2>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginBottom: '1rem'
                }}
              >
                <div className={'send-transaction-container'}>
                  {!wallet && (
                    <button
                      className="bn-demo-button"
                      onClick={async () => {
                        const walletsConnected = await connect()
                        console.log('connected wallets: ', walletsConnected)
                      }}
                    >
                      Select a Wallet
                    </button>
                  )}
                  {wallet && !verifyed && (
                    <button
                      className="bn-demo-button"
                      onClick={handleSignMessage}
                    >
                      Sign and Verify
                    </button>
                  )}
                  {verifyed && (
                    <div>
                      Please go back to the place where you copied the link to
                      view the results
                    </div>
                  )}
                </div>
              </div>
              <div></div>
            </div>
          </div>
          {/* <div className="container ui-settings">{renderNotifySettings()}</div>
          <div className="container ui-settings">
            {renderAccountCenterSettings()}
          </div> */}
        </div>
      </section>
      <ToastContainer autoClose={5000} position={toast.POSITION.TOP_CENTER} />
      <Footer />
    </main>
  )
}

export default App
